.about-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px 20px; /* Added top padding to account for header */
}

.about-content {
  text-align: center;
  margin-bottom: 4rem;
}

.about-content h1 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #333;
}

.about-squares-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.about-square {
  background-color: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-decoration: none;
}

.about-square:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.about-square h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.about-square p {
  color: #666;
  text-align: center;
}

.about-square-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #58213B;
}

/* Section styling */
section {
  margin: 4rem 0;
  padding: 2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #4a6da7;
  padding-bottom: 0.5rem;
}

section p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
}

@media (max-width: 768px) {
  .about-squares-container {
    grid-template-columns: 1fr;
  }
  
  .about-square {
    min-height: 180px;
  }
}
