.contact-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px 40px;
  color: #333;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.page-header {
  text-align: center;
  margin-bottom: 40px;
}

.page-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
}

.page-header p {
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.contact-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.option-card {
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 25px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.option-card:hover {
  background-color: #f0f0f0;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #58213B;
  font-size: 24px;
  background-color: transparent;
}

.option-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.option-card p {
  color: #666;
  font-size: 0.9rem;
}

.prayer-form-section {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 40px 70px;
  margin-bottom: 40px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.prayer-form-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.form-container {
  max-width: 700px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-field {
  margin-bottom: 20px;
}

.form-field-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-field label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: #333;
}

.form-field input,
.form-field select,
.form-field textarea {
  width: 100%;
  padding: 12px 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
}

.form-field input:focus,
.form-field select:focus,
.form-field textarea:focus {
  outline: none;
  border-color: #58213B;
  box-shadow: 0 0 0 2px rgba(88, 33, 59, 0.1);
}

.radio-options {
  display: flex;
  gap: 20px;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #333;
}

.radio-label input {
  margin-right: 8px;
  width: auto;
}

.captcha-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.captcha-checkbox {
  display: flex;
  align-items: center;
}

.captcha-checkbox input {
  width: auto;
  margin-right: 10px;
}

.captcha-checkbox label {
  color: #333;
}

.recaptcha-logo {
  width: 60px;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 3px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #58213B;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #7a2e52;
}

button[type="submit"].loading {
  background-color: #999;
  cursor: not-allowed;
}

.form-submitted button {
  background-color: #28a745;
}

.success-message {
  margin-top: 20px;
  padding: 15px;
  background-color: rgba(40, 167, 69, 0.1);
  border: 1px solid #28a745;
  border-radius: 5px;
  text-align: center;
  color: #28a745;
}

.contact-info {
  margin-top: 60px;
}

.contact-info h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.contact-details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.contact-item {
  display: flex;
  gap: 15px;
}

.contact-icon {
  width: 40px;
  height: 40px;
  border-radius: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #58213B;
  font-size: 20px;
  background-color: transparent;
}

.contact-item h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #333;
}

.contact-item p {
  color: #666;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .form-field-row {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .contact-details {
    grid-template-columns: 1fr;
  }
  
  .contact-options {
    grid-template-columns: 1fr;
  }
} 