.home-page-event-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.home-page-event-card {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  cursor: pointer;
  transition: transform 0.1s ease;
}

.event-title {
  position: absolute;
  font-size: 0.8em;
  top: 20px;
  left: 25px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0.6;
}

.event-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Special style just for Black History card */
.home-page-event-card[data-event="Black History Celebration"] {
  padding-top: 125%; /* Adjust this value to match the flyer's aspect ratio */
}

/* Special style for First Fruits Rally card */
.home-page-event-card[data-event="First Fruits Rally"] {
  padding-top: 125%; /* Adjust this value to match the flyer's aspect ratio */
}

.home-page-event-card:hover {
  transform: scale(1.03);
}

.placeholder-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
}

@media (max-width: 768px) {
  .home-page-event-card:hover {
    transform: none;
  }
}
